<template>
    <div class="promotion">
        <div >
            <img src="../assets/logo.png" style="width: 70px;" />
        </div>
   
        <ul class="tabs">
           
            <li :class="{ 'tab active': true, 'active': activeTab === 1 }" @click="isswitch(1,2)"> 
                苹果书签版<br/>下载
            </li>
            <li :class="{ 'tab active': true, 'active': activeTab === 0 }" @click="isswitch(0,1)">
                苹果自签IPA<br/>下载
            </li>
        </ul>
        <div v-if="activeTab==0">
            <!-- <div >
            <a href="/semi.ipa">
            <img src="../assets/2.png" class="title_img" />
           </a>
        </div> -->
        <div  style=" color: #ffffff;font-size: 16px; margin-top: 15px;text-align: left;">  
            <span style=" font-weight: bold;"> 自签版说明：</span>
        </div>
        <div class="text_div">
            不受限制可访问所有平台，需要一定的安装基础与动手能力。无法自动更新，需至官网：semi.club 手动下载更新。
        </div>
        
        <div class="text_div">
            1. 手机系统版本在IOS16以上安装此软件需要打开手机开发者模式,否者此软件有闪退故障(开发者模式打开方式自行百度)
        </div>
        <div class="text_div">
            2. 爱思助手ID自签一定要先退爱思助手appleid账号。然后手机链接电脑爱思助手读取到手机后重新登录appleid通过ID自签才能成功。
        </div>
        <div class="text_div">
            3. 如遇其他问题请自行百度最新视频教程。
        </div>
        <div class="text_div">
            各类自签教程链接
        </div>
        <div class="text_div">
            巨魔安装教程：<a href="https://ios.cfw.guide/installing-trollstore/">查看安装教程</a>
        </div>
        <div class="text_div">
            轻松签签名教程：<a href="https://esign.yyyue.xyz" >查看安装教程</a>
        </div>
        <div class="text_div">
            sideloadly苹果ID自签教程：<a href="https://youtu.be/FITyezceydo">查看安装教程</a>
        </div>

        <div class="text_div">
            牛蛙助手教程：<a href="https://ios222.com/">查看安装教程</a>
        </div>

        <div class="text_div">
            NB助手教程：<a href="https://nbtool8.com/">查看安装教程</a>
        </div>

        <div class="text_div">
            爱思助手教程：<a href="https://www.i4.cn/news_detail_38195.html">查看安装教程</a>
        </div>

        <div class="text_div">
           其余安装自签请自行研究。
        </div>

        <div class="text_div" style="color: #ced00c; text-align: left; "> 
          
           <p>温馨提示：<br/>出现以下提示（请选择第一个或者第二个进行授权网络，然后退出”色迷聚合APP“，重新打开”色迷聚合APP“）</p> 
        </div>
        <div>
            <img src="../assets/err.png" class="title_img" />

        </div>

        </div>

          <div v-if="activeTab==1">
        <!-- <div >
            <a href="/semi.mobileconfig">
            <img src="../assets/2.png" class="title_img" />
           </a>
        </div> -->
        <div  style=" color: #ffffff;font-size: 16px; margin-top: 15px;text-align: left;">  
            <span style=" font-weight: bold;">书签版说明：</span><br/><br/>
            安装即可使用，由于浏览器限制部分平台可能会出现无法播放等情况。如遇到任何问题或无法访问，请至官网：semi.club 重新下载或联系客服。
        </div>


        <div class="text_div">
             1. 安装轻量版所需描述文件。此描述文件已通过苹果官方认证, 安全可靠。
        </div>
        <div >
            <img src="../assets/23.png" class="title_img" />
        </div>
        <div class="text_div">
             2. 点击允许后，首先在桌面主屏幕找到 “设置” 进入设置点击<span>“已下载描述文件”</span>
        </div>

        <div >
            <img src="../assets/ffg.png" class="title_img" />
        </div>
        <div class="text_div">
             3. 最后按照提示一路点击右上角的<span>“安装”</span> 或 <span>“下一步”</span>即可完成安装
        </div>

        <div >
            <img src="../assets/52.png" class="title_img" />
        </div>
        <div class="text_div">
            如果您设置了锁屏密码，安装时需要输入锁屏密码后才能继续
        </div>
 
          </div>
           
    </div>
    
</template>

<style>
    .promotion{
        max-width: 600px;
        margin: 0 auto; /* 居中显示 */
        display: flex;
        flex-direction: column; 
        padding: 10px; 
        text-align: center;
        height: 100%; 
       
    }

 

        .tabs li { 
        margin: 5px 0;
        padding: 10px 15px;
        background-color: #bb65ad;
        color: white;
        border: none;
        border-radius: 10px;
        width: 100px;
        cursor: pointer;
        }



   
    .title_img{
        max-width: 80%;
         height: auto;
         margin-top: 15px;
    }
 
    .text_div{
        color: #ffffff;
        font-size: 16px;
        margin-top: 15px;
        text-align: left;
    }

    .text_div span{
        color: #ced00c;
    }

    .tabs {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0; 
            justify-content: space-evenly; /* 使用 space-between 使按钮两侧对齐 */
            padding: 1.5vh 0; /* 为上下添加间距 */
           
        }
        .tab {
            cursor: pointer;
            padding: 10px 15px;
            border: 2px solid #ced00c; 
            border-radius: 5px 5px 0 0; 
            width: 50%;
            margin: 1px;
            border-bottom: 2px solid #ced00c;
            color: #ced00c;
        }
        .active {
            /* background-color: #7266d8; */
        }

        .text_div a{
            color: #bb65ad;
            text-decoration: none;


        }
  
</style>

<script>
import { ref } from 'vue'
 
export default {
  name: 'iosView',

  setup() {

    const activeTab=ref(1); 
    const isswitch = (num,str) => { 
        activeTab.value=num;
        var queryString = window.location.search;    
        if(str==1){
            if(queryString){
                queryString= queryString+"&type=2&platformId=1"
            }else{
                queryString= "?type=2&platformId=1"
            }  
            
        }else{ 
            if(queryString){
                queryString= queryString+"&type=3&platformId=1"
            }else{
                queryString= "?type=3&platformId=1"
            } 
            
        }  

       //  window.location.href ='/ios'
        window.location.href = "/dowd" + queryString; 
    } 
    return {
        activeTab,
        isswitch,
    }
  } 
}
</script>